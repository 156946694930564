import { render, staticRenderFns } from "./TermsAndConditionsOfUse.vue?vue&type=template&id=3b7481b6&scoped=true&"
var script = {}
import style0 from "./TermsAndConditionsOfUse.vue?vue&type=style&index=0&id=3b7481b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7481b6",
  null
  
)

export default component.exports